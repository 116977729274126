import { atom, WritableAtom } from 'jotai';
import { DashboardView, StepThroughData } from './interfaces';

function atomWithLocalStorage<T>(key: string, initialValue: any) {
  const getInitialValue = () => {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item);
    }
    return initialValue;
  };
  const baseAtom = atom(getInitialValue());
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue =
        typeof update === 'function' ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      localStorage.setItem(key, JSON.stringify(nextValue));
    }
  );
  return derivedAtom as WritableAtom<T, T | ((arg: T) => T), void>;
}

export const stepThroughDataAtom = atom<StepThroughData>({
  contractType: null,
  formAnswers: [],
  wizardIndex: {
    step: 0,
    subStep: 0
  },
  showHelpCenter: false,
  helpCenterTarget: '',
  negotiation: null
});

export const userAtom = atom<{
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  role?: string;
  currentStripePricePlanId?: string
} | null>(null);

export const redirectAtom = atom<string | null>(null);

export const dashboardViewAtom = atom<DashboardView>('Main');

export const navbarColorAtom = atom<string | undefined>(undefined);
