import React, { useEffect, useState } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  useColorModeValue,
  Heading,
  Button,
  BoxProps,
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useAtom } from 'jotai';

import { stepThroughDataAtom, userAtom } from '../../atoms';
import { parseConditions } from '../../utils/parseConditions';
import { parseAnswers } from '../../utils/parseAnswers';
import { ChartaText } from '../core/ChartaText';
import { parseQuestionLabel } from '../../utils/parseQuestionLabel';
import { ChartaField } from '../form/ChartaField';
import { ChartaBack } from '../core/ChartaBack';
import { Next } from '../icons/Next';
import { createNegotiation, updateNegotiation } from 'src/api';
import { Answer } from 'src/interfaces';
import { useNavigate } from 'react-router-dom';
import { sanitizeAnswers } from 'src/utils/sanitizeAnswers';
import { ChartaProse } from '../core/ChartaProse';
import { AlertModal } from '../core/AlertModal';
import { useApiHelpers } from 'src/hooks/useApiHelpers';
import { stepThroughTheme } from 'src/theme/theme';

export interface WizardProps extends BoxProps {
  onGoBack: () => void;
}

export const Wizard: React.FC<WizardProps> = ({ onGoBack, ...props }) => {
  const bg = stepThroughTheme.background;
  const tooltipColor = useColorModeValue('#6B30BA', 'brand.primary');

  const { showLoading, hideLoading, showServerError } = useApiHelpers();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();
  const [subStep, setSubStep] = useState<any>();
  const [stepThroughData, setStepThroughData] = useAtom(stepThroughDataAtom);
  const {
    contractType: contract,
    wizardIndex,
    formAnswers: answers
  } = stepThroughData;
  const {
    form: { steps },
    conditions,
    validations
  } = contract!;
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting }
  } = useForm();
  //const {isOpen, onClose} = useDisclosure();
  const [tooltipState, setTooltipState] = useState<{
    header: string;
    content: string;
    isOpen: boolean;
  }>({
    header: '',
    content: '',
    isOpen: false
  });

  watch();

  useEffect(() => {
    //debugger;
    const step = wizardIndex.step;
    const subStep = wizardIndex.subStep;
    setSubStep(steps[step].subSteps[subStep]);
    reset(parseAnswers(answers));
  }, [wizardIndex, answers, reset, steps]);

  const onSubmit = async (values: any) => {
    let answersCopy = [...answers];

    Object.keys(values).forEach((key) => {
      let index = answersCopy.findIndex(
        (answer: Answer) => answer.fieldId === key
      );
      if (index > -1) {
        answersCopy[index].value = values[key];
      } else {
        const step = steps[wizardIndex.step];
        const subStep = steps[wizardIndex.step].subSteps[wizardIndex.subStep];
        const question = subStep.questions.find(
          (question: any) => question.field.id === key
        );

        answersCopy.push({
          fieldId: key,
          stepId: step.id,
          subStepId: subStep.id,
          questionId: question.id,
          value: values[key]
        });
      }
    });

    answersCopy = sanitizeAnswers(answersCopy, conditions);

    let subStepIndex = wizardIndex.subStep + 1;

    for (let i = wizardIndex.step; i < steps.length; i++) {
      for (let j = subStepIndex; j < steps[i].subSteps.length; j++) {
        const subStepId = steps[i].subSteps[j].id;
        const condition = conditions.subSteps.find((c: any) => c[subStepId]);

        if (
          !condition ||
          (condition && parseConditions(condition, parseAnswers(answersCopy)))
        ) {
          const newData = { ...stepThroughData };
          setStepThroughData({
            ...newData,
            formAnswers: answersCopy,
            wizardIndex: {
              step: i,
              subStep: j
            }
          });

          return;
        } else {
          //const subStepId = steps[i].subSteps[j].id;
          answersCopy = answersCopy.filter(
            (answer: Answer) => answer.subStepId !== subStepId
          );
        }
      }
      subStepIndex = 0;
    }

    //TODO: move this to parent component
    let response: any;

    try {
      showLoading();

      if (stepThroughData.negotiation) {
        response = await updateNegotiation(stepThroughData.negotiation, {
          answers: answersCopy
        });
      } else {
        response = await createNegotiation({
          contractTypeId: contract!._id,
          answers: answersCopy
        });
      }
    } catch (error) {
      hideLoading();
      showServerError();
    } finally {
      hideLoading();
    }

    navigate(`/negotiation/${response.data._id}`, {
      replace: true,
      state: {
        showSentAlert: true
      }
    });
  };

  // const goToNegotiation = () => {
  //   navigate(`/negotiation/${negotiation._id}`, {
  //     replace: true,
  //   });
  // };

  const onBack = () => {
    let answersCopy = [...answers];
    let subStepIndex = wizardIndex.subStep - 1;

    for (let i = wizardIndex.step; i > -1; i--) {
      for (let j = subStepIndex; j > -1; j--) {
        const subStepId = steps[i].subSteps[j].id;
        const condition = conditions.subSteps.find((c: any) => c[subStepId]);

        if (
          !condition ||
          (condition && parseConditions(condition, parseAnswers(answersCopy)))
        ) {
          const newData = { ...stepThroughData };
          setStepThroughData({
            ...newData,
            wizardIndex: {
              step: i,
              subStep: j
            }
          });
          return;
        } else {
          const subStepId = steps[i].subSteps[j].id;
          answersCopy = answersCopy.filter(
            (answer: any) => answer.subStep !== subStepId
          );
        }
      }

      if (i !== 0) {
        subStepIndex = steps[i - 1].subSteps.length - 1;
      }
    }

    onOpen();
  };

  const onShowTooltip = (tooltip: any) => {
    setTooltipState({
      ...tooltip,
      isOpen: true
    });
  };

  const onCloseTooltip = () => {
    setTooltipState({
      header: '',
      content: '',
      isOpen: false
    });
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      gap="16px"
      px="16px"
      py="24px"
      borderRadius="5px"
      bg={bg}
      sx={{ backdropFilter: 'blur(120px)' }}
      filter="drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25))"
      as="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Flex
        px={{ base: '0', lg: '48px' }}
        flex="1"
        flexDir="column"
        justifyContent="space-between"
        overflow="auto"
      >
        {subStep && (
          <Flex flexDir="column">
            <Heading
              as="h1"
              fontSize="56px"
              lineHeight="48px"
              fontWeight="400"
              color={stepThroughTheme.headingTextColor}
            >
              {subStep.title}
            </Heading>

            {subStep.questions
              .filter((question: any) => {
                const condition = conditions.questions?.find(
                  (c: any) => c[question.id]
                );
                if (!condition) {
                  return true;
                } else {
                  return parseConditions(condition, {
                    ...parseAnswers,
                    ...getValues()
                  });
                }
              })
              .map((question: any, index: number) => {
                const pathWithDefaultValue = question.pathWithDefaultValue
                  ? parseConditions(
                      question.pathWithDefaultValue.condition,
                      parseAnswers(answers)
                    )
                  : null;

                const hidden = question.hidden
                  ? parseConditions(question.hidden, parseAnswers(answers))
                  : false;
                const defaultValue = pathWithDefaultValue
                  ? {
                      pathToDefaultValue: question.pathWithDefaultValue.value,
                      objectWithDefaultValue: { user }
                    }
                  : {};
                return (
                  <Box
                    key={question.id}
                    style={{
                      ...(hidden && {
                        display: 'none'
                      })
                    }}
                  >
                    <ChartaText
                      mt={index === 0 ? '16px' : '32px'}
                      fontFamily="Open Sans, sans-serif"
                      color={stepThroughTheme.labelTextColor}
                    >
                      {parseQuestionLabel(question, {
                        ...answers,
                        ...getValues()
                      })}
                    </ChartaText>

                    <ChartaText
                      onClick={() => onShowTooltip(question.tooltip)}
                      color={tooltipColor}
                      fontSize="12px"
                      lineHeight="20px"
                      fontWeight="700"
                      textDecoration="underline"
                      _hover={{ cursor: 'pointer' }}
                      hidden={!question.tooltip}
                      mb="12px"
                    >
                      {question.tooltip?.label}
                    </ChartaText>

                    <FormControl
                      key={question.field.id}
                      isInvalid={!!errors[question.field.id]}
                      mt="8px"
                    >
                      <ChartaField
                        field={question.field}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        validations={validations}
                        control={control}
                        {...defaultValue}
                        w={{ base: '100%', lg: '80%', xl: '50%' }}
                        color={stepThroughTheme.inputTextColor}
                        borderColor={stepThroughTheme.inputOutlineColor}
                      />
                      <FormErrorMessage>
                        {errors[question.field.id] &&
                          (errors[question.field.id]?.message as string)}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                );
              })}
          </Flex>
        )}
      </Flex>
      <Flex justifyContent="space-between">
        <ChartaBack onClick={onBack} />
        <Button
          type="submit"
          rightIcon={<Next h="9px" width="9px" />}
          disabled={isSubmitting}
          data-heap-id={`${contract!.name}.${wizardIndex.step}.${
            steps[wizardIndex.step].id
          }.${wizardIndex.subStep}.${
            steps[wizardIndex.step].subSteps[wizardIndex.subStep].id
          }.${answers[0].value}`}
          color={stepThroughTheme.buttonTextColor}
        >
          Next
        </Button>
      </Flex>
      <Drawer
        onClose={onCloseTooltip}
        isOpen={tooltipState.isOpen}
        placement="right"
      >
        <DrawerOverlay />
        <DrawerContent p="16px" bg="rgba(27, 28, 49, 1)" bgColor={'black'}>
          <Heading fontSize="32px">{tooltipState.header}</Heading>
          <ChartaProse mt="16px">{tooltipState.content}</ChartaProse>
        </DrawerContent>
      </Drawer>
      <AlertModal
        isOpen={isOpen}
        onAccept={() => onGoBack()}
        onDecline={onClose}
        acceptText="Continue"
        declineText="Cancel"
        title="Are you sure you want to start over?"
        text="If you leave now, you will lose all your progress."
        variant="warning"
      />
    </Flex>
  );
};
