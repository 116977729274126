import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Settings: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 21 20" {...props}>
      <path
        d="M7.52534 20L7.12534 16.8C6.90868 16.7167 6.70468 16.6167 6.51334 16.5C6.32134 16.3833 6.13368 16.2583 5.95034 16.125L2.97534 17.375L0.225342 12.625L2.80034 10.675C2.78368 10.5583 2.77534 10.4457 2.77534 10.337V9.662C2.77534 9.554 2.78368 9.44167 2.80034 9.325L0.225342 7.375L2.97534 2.625L5.95034 3.875C6.13368 3.74167 6.32534 3.61667 6.52534 3.5C6.72534 3.38333 6.92534 3.28333 7.12534 3.2L7.52534 0H13.0253L13.4253 3.2C13.642 3.28333 13.8463 3.38333 14.0383 3.5C14.2297 3.61667 14.417 3.74167 14.6003 3.875L17.5753 2.625L20.3253 7.375L17.7503 9.325C17.767 9.44167 17.7753 9.554 17.7753 9.662V10.337C17.7753 10.4457 17.7587 10.5583 17.7253 10.675L20.3003 12.625L17.5503 17.375L14.6003 16.125C14.417 16.2583 14.2253 16.3833 14.0253 16.5C13.8253 16.6167 13.6253 16.7167 13.4253 16.8L13.0253 20H7.52534ZM10.3253 13.5C11.292 13.5 12.117 13.1583 12.8003 12.475C13.4837 11.7917 13.8253 10.9667 13.8253 10C13.8253 9.03333 13.4837 8.20833 12.8003 7.525C12.117 6.84167 11.292 6.5 10.3253 6.5C9.34201 6.5 8.51268 6.84167 7.83734 7.525C7.16268 8.20833 6.82534 9.03333 6.82534 10C6.82534 10.9667 7.16268 11.7917 7.83734 12.475C8.51268 13.1583 9.34201 13.5 10.3253 13.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};
