import React from "react";
import { Flex, FlexProps, Heading, Divider } from "@chakra-ui/react";
import { ChartaBack } from "../core/ChartaBack";
import { useAtom } from "jotai";
import { dashboardViewAtom } from "src/atoms";

export interface ListHeaderProps extends FlexProps {
  title: string;
}

export const ListHeader: React.FC<ListHeaderProps> = ({ title, ...props }) => {
  const [, setView] = useAtom(dashboardViewAtom);
  return (
    <Flex flexDir="column" alignItems="flex-start" {...props}>
      <ChartaBack
        label="Back"
        pl="0"
        onClick={() => setView("Main")}
      />
      <Heading mt="18px" fontSize="40px" lineHeight="48px" fontWeight="400">
        {title}
      </Heading>
      <Divider />
    </Flex>
  );
};
