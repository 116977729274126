export const addCommasToNumber = (value: any, ignore?: boolean): any => {
  if (ignore) {
    return value;
  }

  const number = Number(value);

  if (!number) {
    return value;
  } else {
    return number.toLocaleString();
  }
};
