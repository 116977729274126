import React, { createContext, ReactNode, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Text
} from '@chakra-ui/react';
import { Close } from 'src/components/icons/Close';

export interface DemoVideoContextProps {
  showVideo: () => void;
  hideVideo: () => void;
}

export const DemoVideoContext = createContext<DemoVideoContextProps>({
  showVideo: () => {},
  hideVideo: () => {}
});

export const DemoVideoProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const showVideo = () => {
    setIsOpen(true);
  };

  const hideVideo = () => {
    setIsOpen(false);
  };

  return (
    <DemoVideoContext.Provider value={{ showVideo, hideVideo }}>
      {children}
      <Modal
        isOpen={isOpen}
        onClose={hideVideo}
        size={{ base: 'sm', lg: '6xl' }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent position="relative" height="90%" overflowY="auto">
          <Close
            _hover={{ cursor: 'pointer' }}
            h="10px"
            w="10px"
            color="brand.primary"
            onClick={hideVideo}
            position="absolute"
            top="10px"
            right="10px"
          />

          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/4sUi5tF41cQ`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </ModalContent>
      </Modal>
    </DemoVideoContext.Provider>
  );
};
