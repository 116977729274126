import { Button, Card, Flex } from '@chakra-ui/react';
import React from 'react';
import { ChartaButtonProps } from '../core/ChartaButton';

export interface ActionCardProps {
  label?: string | React.ReactElement;
  button?: ChartaButtonProps;
  cardStyle?: any;
}

export const ActionCard: React.FC<ActionCardProps> = ({
  label,
  button,
  cardStyle,
  ...props
}) => {
  return (
    <Card style={{ ...cardStyle, width: '100%' }} {...props}>
      <Flex justifyContent="space-between" alignItems="center" p="16px">
        <Flex>{label || ''}</Flex>
        <Flex>
          {button && (
            <Button
              variant={button?.buttonVariant ?? 'outline'}
              size={button?.buttonSize ?? 'md'}
              style={{ ...button?.btnStyle }}
              onClick={() => button?.buttonAction?.()}
              color="#7BFF66"
              disabled={button?.disabled != null ? button?.disabled : false}
            >
              {button?.buttonText ?? 'Select'}
            </Button>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};
