import React from "react";
import { Text, TextProps } from "@chakra-ui/react";

import { useInterpolation } from "../../hooks/useInterpolation";

export interface ChartaTextProps extends TextProps {
  answers?: any;
}

export const ChartaText: React.FC<ChartaTextProps> = ({
  children,
  answers,
  ...props
}) => {
  const { formattedText } = useInterpolation(children, answers);

  return <Text {...props}>{formattedText}</Text>;
};
