import React, { createContext, ReactNode, useState } from "react";
import { LoadScreen } from "../components/core/LoadScreen";

export interface LoadingContextProps {
  showLoading: () => void;
  hideLoading: () => void;
}

export const LoadingContext = createContext<LoadingContextProps>({
  showLoading: () => {},
  hideLoading: () => {},
});

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const component = loading ? <LoadScreen /> : children;

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading }}>
      {component}
    </LoadingContext.Provider>
  );
};
