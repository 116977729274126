export const getClientReferenceId = () => {
  try {
    return (
      (window?.Rewardful && window?.Rewardful?.referral) ||
      'checkout_' + new Date().getTime()
    );
  } catch (err) {
    console.error('Caught error', err);
  }
};
