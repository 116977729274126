import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Dropdown: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 8 5" {...props}>
      <path
        d="M4 4.00293L0 0.00292969L8 0.00292969L4 4.00293Z"
        fill="currentColor"
      />
    </Icon>
  );
};
