import React from "react";
import { Prose, ProseProps } from "@nikolovlazar/chakra-ui-prose";

import { useInterpolation } from "../../hooks/useInterpolation";
import { Box } from "@chakra-ui/react";

export interface ChartaProseProps extends ProseProps {}

export const ChartaProse: React.FC<ChartaProseProps> = ({
  children,
  ...props
}) => {
  const { formattedText } = useInterpolation(children);

  return (
    <Prose >
      <Box {...props}
        dangerouslySetInnerHTML={{
          __html: formattedText!,
        }}
      ></Box>
    </Prose>
  );
};
