import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
  Flex,
  useColorModeValue,
  BoxProps,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { stepThroughDataAtom } from "../../atoms";
import { ChartaText } from "./ChartaText";
import { ChartaProse } from "./ChartaProse";
import { Close } from "../icons/Close";

//TODO: use Chakra-ui drawer for the help center: https://chakra-ui.com/docs/components/drawer/usage

export interface HelpCenterProps extends BoxProps {}

export const HelpCenter: React.FC<HelpCenterProps> = ({ ...props }) => {
  const bg = useColorModeValue(
    "rgba(255, 255, 255, 0.94)",
    "rgba(27, 28, 49, .5)"
  );
  const titleColor = useColorModeValue("#6B30BA", "brand.primary");
  const fontColor = useColorModeValue("#1B1C31", "brand.secondary1");

  const [stepThroughData, setStepThroughData] = useAtom(stepThroughDataAtom);
  const {
    wizardIndex,
    contractType: contract,
    helpCenterTarget: target,
    showHelpCenter,
  } = stepThroughData;

  const { form } = contract!;
  const tooltips: any[] | undefined =
    form.steps[wizardIndex.step].subSteps[wizardIndex.subStep].tooltips;
  const index = tooltips?.findIndex((tooltip: any) => tooltip.id === target);

  const onClose = () => {
    setStepThroughData((data) => ({ ...data, showHelpCenter: false }));
  };

  return (
    <Box
      display={showHelpCenter ? "initial" : "none"}
      w="264px"
      px="16px"
      py="32px"
      bg={bg}
      sx={{ backdropFilter: "blur(120px)" }}
      {...props}
    >
      <Flex justifyContent="flex-end">
        <Close
          _hover={{ cursor: "pointer" }}
          h="10px"
          w="10px"
          color={titleColor}
          onClick={onClose}
        />
      </Flex>
      <Text
        color={fontColor}
        fontSize="24px"
        fontWeight="400"
        lineHeight="20px"
        mb="16px"
      >
        Help Center
      </Text>
      {tooltips && (
        <Accordion index={index}>
          {tooltips.map((tooltip: any) => (
            <AccordionItem key={tooltip.id}>
              <AccordionButton
                onClick={() => {
                  setStepThroughData((data) => ({
                    ...data,
                    helpCenterTarget: tooltip.id,
                  }));
                }}
                pl="0"
              >
                <Box as="span" flex="1" textAlign="left">
                  <ChartaText
                    fontSize="16px"
                    lineHeight="24px"
                    fontWeight="600"
                    color={titleColor}
                  >
                    {tooltip.label}
                  </ChartaText>
                </Box>
              </AccordionButton>

              <AccordionPanel pb={4} pl="0">
                <ChartaProse
                  color={fontColor}
                  fontSize="14px"
                  lineHeight="20px"
                  fontWeight="400"
                >
                  {tooltip.text}
                </ChartaProse>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </Box>
  );
};
