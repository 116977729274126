import React from 'react'
import { Flex } from '@chakra-ui/react';
import {Navigate} from 'react-router-dom';
import { MetaPixel } from '../../components/tracking/MetaPixel';
import { TikTokPixel } from '../../components/tracking/TikTokPixel';

export interface PaymentSuccessfulProps {}

export const PaymentSuccessful = () => {
  return <Flex>
    {/* <MetaPixel/>
    <TikTokPixel/> */}
    <Navigate to="/dashboard"/>
  </Flex>
}
