import React, { useCallback, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { StepThrough } from './pages/StepThrough';
import { Negotiation } from './pages/Negotiation';
import { Dashboard } from './pages/Dashboard';
import { Login } from './pages/Login';


import { Register } from './pages/Register';
import { PasswordReset } from './pages/PasswordReset';
import ProtectedRoute from './components/core/ProtectedRoute';
import { Verify } from './pages/Verify';
import { useAtom } from 'jotai';
import { userAtom } from './atoms';
import { getCurrentUser } from './api';
import { Contract } from './pages/Contract';
import { useApiHelpers } from './hooks/useApiHelpers';
import { ChartaProcess } from './pages/ChartaProcess';
import { PaymentSuccessful } from './pages/tracking/PaymentSuccessful';
import { RegistrationVerified } from './pages/tracking/RegistrationVerified';
import ReactHeap from 'reactjs-heap';
import { PreStepThrough } from './pages/PreStepThrough';

ReactHeap.initialize(`${process.env.REACT_APP_HEAP_ID}`);

function App() {
  const [, setUser] = useAtom(userAtom);
  const { showLoading, showServerError, hideLoading } = useApiHelpers();

  const getUser = useCallback(async () => {
    const { data } = await getCurrentUser();
    setUser(data);
  }, [setUser]);

  useEffect(() => {
    try {
      showLoading();
      getUser();
    } catch {
      hideLoading();
      showServerError();
      setUser(null);
    } finally {
      hideLoading();
    }
  }, [getUser, setUser]);

  return (
    <Routes>
      <Route
        path="/step-through/:id"
        element={
          <ProtectedRoute>
            <StepThrough />
          </ProtectedRoute>
        }
      />
      <Route
        path="/step-through"
        element={
          <ProtectedRoute>
            <StepThrough />
          </ProtectedRoute>
        }
      />
      <Route
        path="/negotiation/:id"
        element={
          <ProtectedRoute>
            <Negotiation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/contract/:id"
        element={
          <ProtectedRoute>
            <Contract />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/*"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pre-step-through"
        element={
          <ProtectedRoute>
            <PreStepThrough />
          </ProtectedRoute>
        }
      />
      <Route
        path="/process"
        element={
          <ProtectedRoute>
            <ChartaProcess />
          </ProtectedRoute>
        }
      />
      <Route
        path="/payment-successful"
        element={
          <ProtectedRoute>
            <PaymentSuccessful />
          </ProtectedRoute>
        }
      />
      <Route
        path="/verify-complete"
        element={
          <ProtectedRoute>
            <RegistrationVerified />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route path="/verify" element={<Verify />} />

      <Route path="/" element={<Navigate to="/dashboard" replace={true} />} />
    </Routes>
  );
}

export default App;
