import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Logout: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 19 18" {...props}>
      <path
        d="M2.27539 18C1.72539 18 1.25439 17.8043 0.862391 17.413C0.471057 17.021 0.275391 16.55 0.275391 16V2C0.275391 1.45 0.471057 0.979 0.862391 0.587C1.25439 0.195667 1.72539 0 2.27539 0H9.27539V2H2.27539V16H9.27539V18H2.27539ZM13.2754 14L11.9004 12.55L14.4504 10H6.27539V8H14.4504L11.9004 5.45L13.2754 4L18.2754 9L13.2754 14Z"
        fill="currentColor"
      />
    </Icon>
  );
};
