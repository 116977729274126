export enum MembershipType {
  BASIC_MONTH = 'basic_1',
  BASIC_ANNUAL = 'basic_12',
  PLUS_MONTH = 'plus_1',
  PLUS_ANNUAL = 'plus_12',
  PRO_MONTH = 'pro_1',
  PRO_ANNUAL = 'pro_12',
  BASIC = 'basic',
  PLUS = 'plus',
  PRO = 'pro',
  BEGINNER = 'single contract'
}
