import {
  Heading,
  useColorModeValue,
  FlexProps,
  Flex,
  FormControl,
  Input,
  Button,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { passwordResetTheme, verifyEmailTheme } from 'src/theme/theme';

export interface EnterPasswordProps extends FlexProps {
  onPasswordEntered: (password: string, confirmPassword: string) => void;
}

export const EnterPassword: React.FC<EnterPasswordProps> = ({
  onPasswordEntered,
  ...props
}) => {
  const color = passwordResetTheme.inputColor;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({});

  const onSubmit = (values: any) => {
    onPasswordEntered(values.password, values.confirmPassword);
  };

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      p="32px"
      as="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Heading
        fontSize="56px"
        fontWeight="400"
        lineHeight="50.4px"
        textAlign="center"
      >
        Reset Password
      </Heading>
      <FormControl
        mt="20px"
        w={{ base: '100%', lg: '70%' }}
        isInvalid={!!errors.password}
      >
        <FormLabel color={color}>New password</FormLabel>
        <Input
          borderColor={color}
          color={color}
          type="password"
          _hover={{ borderColor: passwordResetTheme.inputOutlineHoverColor }}
          {...register('password', { required: true })}
        />
        <FormErrorMessage>Password is required</FormErrorMessage>
      </FormControl>
      <FormControl
        mt="20px"
        w={{ base: '100%', lg: '70%' }}
        isInvalid={!!errors.confirmPassword}
      >
        <FormLabel color={color}>Confirm password</FormLabel>
        <Input
          borderColor={color}
          type="password"
          color={color}
          _hover={{ borderColor: passwordResetTheme.inputOutlineHoverColor }}
          {...register('confirmPassword', {
            required: true,
            validate: (value: string) => {
              if (watch('password') !== value) {
                return 'Your passwords do no match';
              }
            }
          })}
        />
        <FormErrorMessage>
          {errors.confirmPassword?.type === 'required'
            ? 'Password confirmation required'
            : 'Passwords do not match'}
        </FormErrorMessage>
      </FormControl>
      <Button
        mt="24px"
        w="138px"
        h="40px"
        variant="gradient"
        type="submit"
        background={verifyEmailTheme.buttonBackgroundColor}
        color={verifyEmailTheme.buttonTextColor}
      >
        Submit
      </Button>
    </Flex>
  );
};
