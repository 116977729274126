export const parseConditions = (
  query: any,
  test: any,
  key?: string
): boolean => {
  if (Object.keys(query).length > 1) {
    throw new Error("Invalid query!");
  }

  const operator = Object.keys(query)[0];

  if (key) {
    switch (operator) {
      case "eq$":
        return test[key] === query.eq$;
      case "neq$":
        return test[key!] !== query.neq$;
      case "lt$":
        return test[key] < query.lt$;
      case "gt$":
        return test[key] > query.gt$;
      case "lte$":
        return test[key] <= query.lte$;
      case "gte$":
        return test[key] >= query.gte$;
      case "in$":
        return query.in$.includes(test[key]);
    }
  }

  if (!["and$", "or$"].includes(operator)) {
    return parseConditions(query[operator], test, operator);
  }

  if (operator === "and$") {
    if (!Array.isArray(query.and$)) {
      throw new Error("Invalid query!");
    }

    for (let i = 0; i < query.and$.length; i++) {
      if (!parseConditions(query.and$[i], test)) {
        return false;
      }
    }
    return true;
  }

  if (operator === "or$") {
    if (!Array.isArray(query.or$)) {
      throw new Error("Invalid query!");
    }

    for (let i = 0; i < query.or$.length; i++) {
      if (parseConditions(query.or$[i], test)) {
        return true;
      }
    }
    return false;
  }

  throw new Error("Invalid query!");
};
