import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Logistics: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 34 42" {...props}>
      <path
        d="M6 25.6104H27V28.6104C27.4935 28.509 27.9962 28.4587 28.5 28.4604C29.0038 28.4597 29.5063 28.51 30 28.6104V25.1604C30 24.4973 29.7366 23.8614 29.2678 23.3926C28.7989 22.9237 28.163 22.6604 27.5 22.6604H18V15.3604C17.5447 15.6265 17.0273 15.7679 16.5 15.7704C15.9727 15.7679 15.4553 15.6265 15 15.3604V22.6104H5.5C4.83696 22.6104 4.20107 22.8737 3.73223 23.3426C3.26339 23.8114 3 24.4473 3 25.1104V29.1104H6V25.6104ZM8.5 31.6104H0.5C0.367392 31.6104 0.240215 31.663 0.146447 31.7568C0.0526785 31.8506 0 31.9777 0 32.1104V40.1104C0 40.243 0.0526785 40.3701 0.146447 40.4639C0.240215 40.5577 0.367392 40.6104 0.5 40.6104H8.5C8.63261 40.6104 8.75979 40.5577 8.85355 40.4639C8.94732 40.3701 9 40.243 9 40.1104V32.1604C9.00547 32.0909 8.99667 32.0211 8.97413 31.9552C8.95159 31.8893 8.91579 31.8287 8.86895 31.7772C8.8221 31.7257 8.7652 31.6843 8.70174 31.6556C8.63829 31.6269 8.56963 31.6115 8.5 31.6104V31.6104ZM28.5 30.9704C27.4827 30.9684 26.4876 31.2678 25.6403 31.8307C24.793 32.3937 24.1313 33.1949 23.7389 34.1335C23.3464 35.072 23.2407 36.1057 23.435 37.1043C23.6294 38.1028 24.1151 39.0215 24.831 39.7443C25.5468 40.4671 26.4607 40.9617 27.4573 41.1657C28.4539 41.3697 29.4887 41.274 30.4309 40.8907C31.3732 40.5073 32.1809 39.8534 32.752 39.0116C33.3231 38.1697 33.6321 37.1776 33.64 36.1604C33.6466 35.4812 33.5185 34.8074 33.2632 34.178C33.0078 33.5486 32.6303 32.9761 32.1523 32.4935C31.6744 32.0109 31.1055 31.6278 30.4786 31.3663C29.8517 31.1049 29.1792 30.9703 28.5 30.9704Z"
        fill="currentColor"
      />
      <path
        d="M15.8199 13.32V11.83C15.3607 11.794 14.9055 11.717 14.4599 11.6C13.991 11.4948 13.5325 11.3475 13.0899 11.16V8.78C13.5095 9.06146 13.9549 9.30254 14.4199 9.5C14.8526 9.70442 15.3218 9.82003 15.7999 9.84V7.32C15.2558 7.10886 14.7388 6.83359 14.2599 6.5C13.8492 6.23281 13.5068 5.87322 13.2599 5.45C13.0442 5.01907 12.9378 4.54174 12.9499 4.06C12.9323 3.5347 13.053 3.01398 13.2999 2.55C13.5357 2.13162 13.8815 1.78578 14.2999 1.55C14.7759 1.32092 15.2925 1.18837 15.8199 1.16V0H16.9799V1.09C17.3943 1.12438 17.8035 1.20488 18.1999 1.33C18.6224 1.46364 19.031 1.63778 19.4199 1.85L18.8699 3.85C18.5567 3.66141 18.2206 3.51355 17.8699 3.41C17.5744 3.30061 17.2699 3.21696 16.9599 3.16V5.48C17.4901 5.67601 17.9966 5.93094 18.4699 6.24C18.8374 6.49711 19.1361 6.84049 19.3399 7.24C19.5346 7.66644 19.6303 8.13136 19.6199 8.6C19.6522 9.36717 19.3976 10.1189 18.9058 10.7086C18.4141 11.2983 17.7204 11.6838 16.9599 11.79V13.35L15.8199 13.32ZM15.8199 5V3.16C15.6662 3.19272 15.5223 3.26124 15.3999 3.36C15.3038 3.443 15.2314 3.54996 15.1899 3.67C15.1482 3.80604 15.128 3.94773 15.1299 4.09C15.1344 4.20317 15.1581 4.31476 15.1999 4.42C15.2579 4.53014 15.3359 4.62849 15.4299 4.71C15.5488 4.82084 15.6796 4.9181 15.8199 5V5ZM16.9799 9.69C17.1749 9.61988 17.3405 9.48598 17.4499 9.31C17.5408 9.13048 17.5855 8.93113 17.5799 8.73C17.5828 8.60636 17.5589 8.48357 17.5099 8.37C17.4596 8.26304 17.392 8.16507 17.3099 8.08C17.21 7.98411 17.0993 7.90021 16.9799 7.83V9.69Z"
        fill="currentColor"
      />
    </Icon>
  );
};
