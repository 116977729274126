import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Close: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 10 10" {...props}>
      <path d="M1.062 10L0 8.938L3.938 5L0 1.062L1.062 0L5 3.938L8.938 0L10 1.062L6.062 5L10 8.938L8.938 10L5 6.062L1.062 10Z" fill="currentColor"/>
    </Icon>
  );
};
