import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Success: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 102 102" {...props}>
      <circle cx="51" cy="51" r="47.5" stroke="currentColor" strokeWidth="7" fill="transparent" />
      <path
        d="M43.5616 68.0603L28.7734 53.1881L33.1498 48.8117L43.5616 59.1395L68.8498 33.9395L73.2262 38.3999L43.5616 68.0603Z"
        fill="currentColor"
      />
    </Icon>
  );
};
