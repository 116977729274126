import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Warning: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 110 95" {...props}>
      <path
        d="M54.553 3.74849C54.6121 3.64698 54.6742 3.59633 54.7329 3.56411C54.801 3.52667 54.8932 3.5 54.9997 3.5C55.1062 3.5 55.1984 3.52667 55.2665 3.56411C55.3252 3.59633 55.3873 3.64698 55.4464 3.74849L106.129 90.7987C106.288 91.0711 106.119 91.5 105.682 91.5H4.31715C3.88015 91.5 3.71181 91.0711 3.87043 90.7987L54.553 3.74849Z"
        stroke="currentColor"
        strokeWidth="7"
      />
      <path
        d="M58.3998 63.2388H51.7101L50.3118 37.4238H59.7981L58.3998 63.2388ZM50.2021 72.2754C50.2021 70.6196 50.6682 69.457 51.6004 68.7876C52.5509 68.1182 53.6932 67.7835 55.0275 67.7835C56.3253 67.7835 57.4402 68.1182 58.3724 68.7876C59.3229 69.457 59.7981 70.6196 59.7981 72.2754C59.7981 73.8608 59.3229 75.0057 58.3724 75.7104C57.4402 76.415 56.3253 76.7673 55.0275 76.7673C53.6932 76.7673 52.5509 76.415 51.6004 75.7104C50.6682 75.0057 50.2021 73.8608 50.2021 72.2754Z"
        fill="currentColor"
      />
    </Icon>
  );
};
