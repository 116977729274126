import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { radioAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    borderRadius: "3px",
    height: "24px",
    width: "24px",
    borderColor: "rgba(27, 28, 49, 0.75)",
    borderWidth: "1px",
    _hover: {
      background: "#8E30BA",
      borderColor: "#8E30BA",
    },
    _checked: {
      borderColor: "transparent",
      background: "linear-gradient(#8E30BA, #4B1495)",
      _before: null,
      _hover: {
        borderColor: "transparent",
        background: "linear-gradient(#8E30BA, #4B1495)",
      },
    },

    _dark: {
      borderColor: "rgba(255, 255, 255, 0.4)",
      _checked: {
        borderColor: "transparent",
        background: "linear-gradient(#FDE033, #EE4373)",
        _before: null,
        _hover: {
          borderColor: "transparent",
          background: "linear-gradient(#FDE033, #EE4373)",
        },
      },
      _hover: {
        background: "#FDE033",
        borderColor: "#FDE033",
      },
      _invalid: {
        background: "transparent",
        borderColor: "red.300",
      },
    },
  },
});

export const radioTheme = defineMultiStyleConfig({ baseStyle });
