import { Answer } from "../interfaces";
import { sanitizeAnswers } from "./sanitizeAnswers";

export const mergeAndSanitizeAnswers = (
  oldAnswers: Answer[],
  newAnswers: Answer[],
  conditions: any
): Answer[] => {
  const answers = [...oldAnswers];

  for (let i = 0; i < newAnswers.length; i++) {
    const j = answers.findIndex(
      (answer: Answer) => answer.fieldId === newAnswers[i].fieldId
    );
    if (j > -1) {
      answers[j] = { ...newAnswers[i], status: "new" };
      
    } else {
      answers.push({ ...newAnswers[i], status: "new" });
    }
  }

  return sanitizeAnswers(answers, conditions);
};
