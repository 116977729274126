import { parseConditions } from "./parseConditions";

export const parseQuestionLabel = (question: any, answers: any) => {
  let label = question.label;
  const labels = question.conditionalLabels;

  for (let i = 0; i < labels?.length; i++) {
    if (parseConditions(labels[i].condition, answers)) {
      label = labels[i].label;
      break;
    }
  }

  return label;
};
