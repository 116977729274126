import React, { createContext, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertModal } from "../components/core/AlertModal";

export interface ServerErrorContextProps {
  showServerError: (message?: string) => void;
}
export const ServerErrorContext = createContext<ServerErrorContextProps>({
  showServerError: (message?: string) => {},
});

interface AlertState {
  isOpen: boolean;
  message: string;
}
export const ServerErrorProvider = ({ children }: { children: ReactNode }) => {
  const [alertState, setAlertState] = useState<AlertState>({
    isOpen: false,
    message: "Something went wrong!",
  });

  const navigate = useNavigate();

  const onAccept = () => {
    setAlertState({
      isOpen: false,
      message: "",
    });
    navigate("/");
  };

  const showAlert = (message?: string) => {
    setAlertState({
      isOpen: true,
      message: message ? message : "Something went wrong!",
    });
  };

  return (
    <ServerErrorContext.Provider
      value={{
        showServerError: showAlert,
      }}
    >
      {children}
      <AlertModal
        isOpen={alertState.isOpen}
        onAccept={onAccept}
        variant="warning"
        title={alertState.message}
        acceptText="OK"
      />
    </ServerErrorContext.Provider>
  );
};
