import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const PlusCircle: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 72 72" {...props}>
      <path
        d="M36 1.125C16.7344 1.125 1.125 16.7344 1.125 36C1.125 55.2656 16.7344 70.875 36 70.875C55.2656 70.875 70.875 55.2656 70.875 36C70.875 16.7344 55.2656 1.125 36 1.125ZM56.25 39.9375C56.25 40.8656 55.4906 41.625 54.5625 41.625H41.625V54.5625C41.625 55.4906 40.8656 56.25 39.9375 56.25H32.0625C31.1344 56.25 30.375 55.4906 30.375 54.5625V41.625H17.4375C16.5094 41.625 15.75 40.8656 15.75 39.9375V32.0625C15.75 31.1344 16.5094 30.375 17.4375 30.375H30.375V17.4375C30.375 16.5094 31.1344 15.75 32.0625 15.75H39.9375C40.8656 15.75 41.625 16.5094 41.625 17.4375V30.375H54.5625C55.4906 30.375 56.25 31.1344 56.25 32.0625V39.9375Z"
        fill="currentColor"
      />
    </Icon>
  );
};
