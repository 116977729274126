import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";

import { stepThroughDataAtom } from "../atoms";
import { parseAnswers } from "../utils/parseAnswers";

export const useInterpolation = (text: any, answers?: any) => {
  const [stepThroughData] = useAtom(stepThroughDataAtom);
  const { formAnswers } = stepThroughData;

  const [formattedText, setFormattedText] = useState<string>();

  if (!answers) {
    answers = formAnswers;
  }

  useEffect(() => {
    let copy: string = text?.toString();

    if (!copy) return;
    for (const [key, value] of Object.entries(parseAnswers(answers))) {
      copy = copy.replace(`{{${key}}}`, value as string);
    }

    setFormattedText(copy);
  }, [text, answers]);

  return { formattedText };
};
