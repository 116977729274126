// Will open tab in current window
export const openInCurrentTab = (url: string) => {
  const newWindow = window.open(url, '_self');
  if (newWindow) newWindow.opener = null;
};

// Will open tab in new window
export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank');
  if (newWindow) newWindow.opener = null;
};
