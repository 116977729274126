import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Info: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 24 27" {...props}>
      <path
        d="M13.8346 26.6657L13.5013 22.999H12.168C8.99019 22.999 6.27908 21.8768 4.03464 19.6324C1.79019 17.3879 0.667969 14.6768 0.667969 11.499C0.667969 8.32125 1.79575 5.61013 4.0513 3.36569C6.30686 1.12125 9.03463 -0.000976562 12.2346 -0.000976562C13.8124 -0.000976562 15.2735 0.282357 16.618 0.849024C17.9624 1.41569 19.1346 2.21013 20.1346 3.23236C21.1346 4.25458 21.918 5.46013 22.4846 6.84902C23.0513 8.23791 23.3346 9.75458 23.3346 11.399C23.3346 12.8657 23.118 14.3324 22.6846 15.799C22.2513 17.2657 21.6235 18.6657 20.8013 19.999C19.9791 21.3324 18.9791 22.5657 17.8013 23.699C16.6235 24.8324 15.3013 25.8212 13.8346 26.6657ZM12.2346 19.2324C12.5902 19.2324 12.8902 19.1101 13.1346 18.8657C13.3791 18.6212 13.5013 18.3212 13.5013 17.9657C13.5013 17.6101 13.3791 17.3101 13.1346 17.0657C12.8902 16.8212 12.5902 16.699 12.2346 16.699C11.8791 16.699 11.5791 16.8212 11.3346 17.0657C11.0902 17.3101 10.968 17.6101 10.968 17.9657C10.968 18.3212 11.0902 18.6212 11.3346 18.8657C11.5791 19.1101 11.8791 19.2324 12.2346 19.2324ZM11.3346 14.699H13.0013C13.0013 14.1435 13.0957 13.6824 13.2846 13.3157C13.4735 12.949 13.868 12.4657 14.468 11.8657C15.068 11.2657 15.4902 10.7157 15.7346 10.2157C15.9791 9.71569 16.1013 9.1768 16.1013 8.59902C16.1013 7.59902 15.7624 6.7768 15.0846 6.13236C14.4069 5.48791 13.5013 5.16569 12.368 5.16569C11.4346 5.16569 10.6013 5.41013 9.86797 5.89902C9.13463 6.38791 8.59019 7.05458 8.23464 7.89902L9.76797 8.53236C10.0124 7.95458 10.3513 7.5268 10.7846 7.24902C11.218 6.97125 11.7124 6.83236 12.268 6.83236C12.9346 6.83236 13.4569 6.99347 13.8346 7.31569C14.2124 7.63791 14.4013 8.06569 14.4013 8.59902C14.4013 9.02125 14.2791 9.44902 14.0346 9.88236C13.7902 10.3157 13.3569 10.8546 12.7346 11.499C12.1346 12.1212 11.7513 12.6212 11.5846 12.999C11.418 13.3768 11.3346 13.9435 11.3346 14.699Z"
        fill="currentColor"
      />
    </Icon>
  );
};
