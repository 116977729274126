import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Tick: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 18 14" {...props}>
      <path
        d="M6.16631 13.4984L0.532715 7.8328L2.19991 6.1656L6.16631 10.1L15.7999 0.5L17.4671 2.1992L6.16631 13.4984Z"
        fill="currentColor"
      />
    </Icon>
  );
};
